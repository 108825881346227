import React from "react";
import { PageFeatures } from "components";
import { StaticImage } from "gatsby-plugin-image";

const EpaymentsFeatures = () => {
  return (
    <div>
      <PageFeatures
        features={[
          "Easy and fast way to receive payments online",
          "You have the option of receiving payments through cards, bank transfers or USSD",
        ]}
        image={
          <StaticImage
            src="../../../../assets/images/jpegs/epayments/features.jpeg"
            alt="features"
          />
        }
      />
    </div>
  );
};

export { EpaymentsFeatures };
