import * as React from "react";

import { Layout } from "components";
import {
  EpaymentsExploreSection,
  EpaymentsFeatures,
  EPaymentsPageHeader,
} from "templates/solutions/epayments";

const EPaymentsPage = () => (
  <Layout title="E-payments">
    <EPaymentsPageHeader />
    <EpaymentsFeatures />
    <EpaymentsExploreSection />
  </Layout>
);

export default EPaymentsPage;
