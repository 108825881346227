import React from "react";
import { ExploreCard } from "components";
import { StaticImage } from "gatsby-plugin-image";

const EpaymentsExploreSection = () => {
  return (
    <ExploreCard
      subTitle="Want to do more? "
      title="e-Payments"
      description="Simple-to-use payment solution to collect and manage funds 
      across digital platforms."
      buttonText="Explore card services"
      pageLink="/solutions/cards"
      image={
        <StaticImage
          src="../../../../assets/images/jpegs/epayments/simple-banking-infrastructure.jpeg"
          alt="Man smiling"
        />
      }
    />
  );
};

export { EpaymentsExploreSection };
