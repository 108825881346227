import { Br, PageHeader } from "components";
import React from "react";

const EPaymentsPageHeader = () => {
  return (
    <PageHeader
      title={
        <>
          The simplest way to{" "}
          <span className="text-primary">
            accept <Br on="all" />
            payments and make collections <Br on="all" />
            anywhere
          </span>{" "}
          on the internet.
        </>
      }
      subheading={
        <>
          We are a one-stop shop for everything you need to access simple and
          easy <Br on="desktop" />
          online payment options with support from multiple channels like cards,{" "}
          <Br on="desktop" />
          bank transfers, USSD, QR, Wallets etc.
        </>
      }
      button={{ text: "Start Building", to: "/get-demo" }}
    />
  );
};

export { EPaymentsPageHeader };
